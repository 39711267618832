import React from 'react'
import Layout from '../components/Layout'
import SocialMedias from "../components/SocialMedias"

export default function contact() {
    return (
        <Layout>
          <h1>Contact Details</h1>
            {SocialMedias(true)}
        </Layout>
    )
}

